.ajs-hidden {
  display: none!important;
}
.alertify-static-content {
  display: none;
}

.multiForms form {
  display: none;
}

.alertify {
  .ajs-dimmer {
    background-color: #565f69;
    color: #ddd;
    opacity: .5;
  }

  .ajs-dialog {
    max-width: 480px;
    min-height: 122px;
    background-color: #565f69;
    color: #ddd;
    border: 0;
    box-shadow: none;
    border-radius: 0;
    padding: 20px 40px 50px;
  }

  .ajs-header {
    color: #ddd;
    background-color: #565f69;
    margin: 0 0 20px;
    font: 20px HelveticaNeueCyr-bold, sans-serif;
    text-align: center;
    padding: 0;
  }

  .ajs-body {
    background-color: #565f69;

    font-family: 'Roboto', sans-serif;
    color: #ddd;

    .ajs-content {
      padding: 0;
    }
  }

  .ajs-footer {
    background-color: #565f69;
    color: #ddd;
    padding: 15px 15px 0;
    border-top: 0;
    border-radius: 0;
  }

  &.ajs-closable {
    .ajs-commands {
      margin: -3px 15px 0 0;

      button.ajs-close {
        display: block;
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='layer' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 1000 1000' style='enable-background:new 0 0 1000 1000;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg id='r'%3E%3Crect x='1' y='475' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -206.9603 500.3535)' class='st0' width='999' height='50'/%3E%3C/g%3E%3Cg id='r_1_'%3E%3Crect x='475.5' y='0.5' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -206.9603 500.3535)' class='st0' width='50' height='999'/%3E%3C/g%3E%3C/svg%3E");
      }

      button.ajs-close:hover,
      button.ajs-close:focus,
      button.ajs-close:active {
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='layer' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 1000 1000' style='enable-background:new 0 0 1000 1000;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg id='r_1_'%3E%3Crect x='462' y='0.5' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -206.9603 500.3535)' class='st0' width='77.1' height='999'/%3E%3C/g%3E%3Cg id='r_2_'%3E%3Crect x='1' y='461.5' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -206.9603 500.3535)' class='st0' width='999' height='77.1'/%3E%3C/g%3E%3C/svg%3E");
      }
    }
  }
}

/***************
 notifier ajs-message ajs-error ajs-visible
***************/

.alertify-notifier {
  .ajs-message {
    background: rgba(255, 255, 255, .95);
    color: #000;
    border: 0;
    border-radius: 2px;

    &.ajs-success {
      color: #fff;
      background: #60c458;
      text-shadow: none;
    }

    &.ajs-error {
      color: #fff;
      background: #e74c3c;
      text-shadow: none;
    }

    &.ajs-warning {
      background: #f29400;
      border-color: #fff;
    }
  }
}
