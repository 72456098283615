#routeInfoBtn {
  position: fixed;
  z-index: 109;
  top: 0;
  background: #ddd;
  color: #fff;
  left: 0;
  padding: 3px;
  display: block;
  cursor: pointer;
  border-bottom-right-radius: 5px;
}

#shadow {
  display: none;
  z-index: 120;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.27);
}

#playerWrap {
  display: none;
  position: fixed;
  z-index: 121;
  left: 50%;
  top: 50%;
  background: #fff;
}

.plyr__poster {
  display: block !important;
}

.visible {
  display: block
}

.hide {
  display: none
}

.simpleTextLineSpacer {
  display: inline-block;
  width: 8px;
}

.draftBlock {
  width: 100%;
  background: #f8d6a9;
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 8px 100px 3px 0;
  text-align: center;
  color: #3a3a3a;
  z-index: 10;
}